<template>
	<div class="container">

		<div class="main">
			<div class="title">企业文化</div>
			<div class="title2">蕙漾馨福 · 铸造天下馨福婚姻</div>
		</div>

		<div class="content">
			<div class="child">
				<div class="left">
					<div class="top">
						<div class="title">
							大航海精神
						</div>
						<p>千里之行，始于足下！</p>
						<p>船行万里，始于岸边！</p>
						<p class="bottom">人攀云空，始于自心！</p>
					</div>
					<div class="top">
						<div class="title">
							船主寄语三十三字诀
						</div>
						<p>同起程，依自勇，</p>
						<p>同风浪、共船行，</p>
						<p>起凿锤，推海中，</p>
						<p>莫罪主，初已鸣，</p>
						<p>缘得今生，愿得终有你！</p>
					</div>
				</div>

				<div class="right">
					<div class="top">
						<div class="title">
							我们的使命
						</div>
						<p>让中国单身人群变少</p>
						<p>让中国馨福家庭增多</p>
						<p class="bottom2">让中国人口负增逆转</p>
					</div>

					<div class="top">
						<div class="title">
							我们的价值观
						</div>
						<p>勇于创新，追求超越</p>
						<p>同舟共济，乘风弄潮</p>
						<p>用心服务，品质要求</p>
						<p class="bottom2">肩负道义，大爱奉献</p>
					</div>

					<div class="top">
						<div class="title">
							我们的企业愿景
						</div>
						<p>成为世界最具影响力的健康婚恋生态产业链铸造者</p>
					</div>
				</div>
			</div>
		</div>

		<div class="content2">
			<div class="child">
				<div class="item item2">
					<p>企业文化</p>
					<span>company culture</span>
				</div>
				<div class="item" v-for="(item, index) of list" :key="index">
					<div class="left">
						<img :src="item.url" alt="" />
					</div>
					<div class="right">
						<div class="title">{{ item.title }}</div>
						<div class="text">{{ item.text }}</div>
					</div>
				</div>
			</div>
		</div>

		<hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
	</div>
</template>

<script>
	import HyxfFooter from '@/components/footer'

	export default {
		name: 'BusCulture',
		data() {
			return {
				list: [{
						title: '航船扬帆，我们有伟大的目标',
						text: '我们立志打造涵盖健康、婚恋、养老、旅游、教育五大产业的多元化集团公司，为全社会全人类“馨福”生活的实现而奋斗。',
						url: require('@/assets/images/culture_5.png')
					},
					{
						title: '载满期望，我们有奉献的使命',
						text: '我们期望通过不懈的努力实现人间至善的大爱，在健康、婚恋、养老、旅游、教育五大领域承担起服务人民的责任，为社会的美好发展奉献力量。',
						url: require('@/assets/images/culture_6.png')
					},
					{
						title: '积步千里，我们有实干的行动',
						text: ' 面对宏大复杂的社会问题，我们勇于行动，坚信实干的力量，集思广益谱写答卷，一次次推翻与完善，抽丝剥茧，循序渐进，步伐坚定穿行于大雾风雨。',
						url: require('@/assets/images/culture_7.png')
					},
					{
						title: '踏浪而行，我们有进取的团队',
						text: ' 我们有强大的专业人才储备，包括AI智能、软件工程、科技研发、酒店管理、大健康、婚恋交友各领域专业人才，为项目进程保驾护航。',
						url: require('@/assets/images/culture_8.png')
					},
					{
						title: '履践致远，我们有坚持的恒心',
						text: ' 蕙漾馨福创立之初，我们毅然投身健康行业，帮助无数家庭解决了健康问题；如今，面临全社会逐渐严峻的婚恋难题，我们再次出发，踏出为全社会“馨福”婚姻实现的第二步。',
						url: require('@/assets/images/culture_9.png')
					}
				]
			}
		},
		components: {
			HyxfFooter
		},
		methods: {
			changeMenu(index) {
				this.$emit('childChangeMenu', index)
			},
		}


	};
</script>




<style lang="less" scoped>
	.container {
		width: 100%;

		.content2 {
			width: 100%;
			padding: 100px 0;
			background-image: url('@/assets/images/culture_4.png');
			background-size: cover;
			background-position: center;

			.child {
				width: 1280px;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.item {
					width: 600px;
					height: 150px;
					display: flex;
					justify-content: space-between;
					margin-bottom: 50px;

					.left {
						width: 126px;
						height: 150px;

						img {
							width: 100%;
						}
					}

					.right {
						padding: 18px 10px;
						flex: 1;

						.title {
							font-weight: bold;
							margin-bottom: 8px;
						}

						.text {
							line-height: 28px;
						}
					}
				}

				.item2 {
					display: unset !important;

					p {
						font-size: 40px;
						margin-bottom: 16px;
					}

					span {
						color: #676B72;
						letter-spacing: 6px;
					}
				}
			}
		}

		.content {
			padding: 100px 0;
			background: linear-gradient(to bottom, rgb(22 100 255 / 0%), rgb(22 100 255 / 8%));

			.child {
				width: 1280px;
				margin: 0 auto;
				display: flex;

				.right {
					width: 50%;

					.top {
						.title {
							font-size: 32px;
							margin-bottom: 30px;
						}

						p {
							font-size: 24px;
							line-height: 50px;
						}

						.bottom2 {
							margin-bottom: 50px;
						}
					}
				}

				.left {
					width: 50%;

					.top {
						.title {
							font-size: 40px;
							margin-bottom: 40px;
						}

						p {
							font-size: 24px;
							line-height: 50px;
						}

						.bottom {
							margin-bottom: 130px;
						}
					}
				}
			}
		}

		.main {
			width: 100%;
			height: 500px;
			background-image: url('@/assets/images/culture_3.png');
			background-size: cover;
			background-position: center;
			text-align: center;

			.title {
				padding-top: 174px;
				font-size: 60px;
				font-weight: bold;
				margin-bottom: 40px;
			}

			.title2 {
				font-size: 32px;
				opacity: 0.8;
			}
		}
	}
</style>