<template>
  <div class="container">

    <div class="main">
      <div class="title">新闻动态</div>
      <div class="title2">行业最新前沿技术分享</div>
    </div>

    <div class="content">
      <div class="news">
        <div class="item" v-for="(item, index) of list" :key="index" @click="toDetail(index)">
          <div class="left">
            <img :src="item.url" alt="" />
          </div>
          <div class="right">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="text">
              {{ item.text }}
            </div>
            <div class="time">
              {{ item.time }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
  </div>
</template>

<script>
  import HyxfFooter from '@/components/footer'

  export default {
    name: 'HyxfNews',
    data() {
      return {
        list: [
          {
            url: require('@/assets/images/news_1.png'),
            title: '汪洋先生带领团队研发的婚恋社交平台即将上线，现场活动十万礼金等你来领取！',
            text: '汪洋先生参加江苏卫视闪光创始人节目，详细阐述蕙漾馨福网络科技的商业模式和功能模块，现场观众和创业者纷纷表示赞赏。2024年1月，蕙漾馨福APP将上线，并举办大型线下活动。',
            time: '2023年11月13日'
          },
          {
            url: require('@/assets/images/news_2.png'),
            title: '蕙漾馨福技术团队与阿里巴巴合作共谋亿万用户服务架构',
            text: '12月19日，蕙漾馨福与阿里巴巴技术团队交流，探讨服务架构挑战。阿里巴巴团队分享亿万用户服务经验，解决蕙漾馨福技术问题，为APP上线后应对千万用户涌入提供解决思路。',
            time: '2023年12月19日'
          },
          {
            url: require('@/assets/images/news_3.png'),
            title: '2023年蕙漾馨福沙龙深圳召开，2024春蕾行动启航仪式即将举行！',
            text: '11月，江苏卫视全程报道的蕙漾馨福沙龙在深圳市招商前海经贸中心举行，公司创始人汪洋详细描述战略成果，表达愿景。1月，在深圳欢乐海岸将举行蕙漾馨福春蕾行动。',
            time: '2023年11月01日'
          },
          {
            url: require('@/assets/images/news_4.jpeg'),
            title: '蕙漾馨福APP全新上线，多重好礼等你参加',
            text: '结婚领证送10万礼金，获奔驰豪车抽奖资格',
            time: '2023年12月20日'
          }
        ]
      }
    },
    components: {
      HyxfFooter
    },
    methods: {
      toDetail(index) {
        // this.$emit('newsDetail', index)
        this.$router.push('newsDetail')
      },
      changeMenu(index) {
        this.$emit('childChangeMenu', index)
      }
    }


  };
</script>




<style lang="less" scoped>
  .container {
    width: 100%;

    .main {
      height: 100%;
      height: 500px;
      background-image: url('@/assets/images/aboutus_6.png');
      background-size: 100%;
      text-align: center;
      .title {
        padding-top: 174px;
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .title2 {
        font-size: 32px;
        opacity: 0.8;
      }
    }

    .content {
      padding: 100px 0;
      .news {
        width: 1280px;
        margin: 0 auto;
        .item {
          padding: 36px;
          width: 1208px;
          height: 196px;
          box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.08);
          border-radius: 8px;
          display: flex;
          margin-bottom: 40px;
          cursor: pointer;
          &:hover {
            background: linear-gradient(to bottom, rgb(22 100 255 / 0%), rgb(22 100 255 / 8%));
          }
          .left {
            width: 320px;
            height: 192px;
            margin-right: 40px;
            img {
              width: 100%;
            }
          }
          .right {
            flex: 1;
            .title {
              font-size: 24px;
              margin-bottom: 24px;
            }
            .text {
              opacity: 0.6;
              line-height: 28px;
              margin-bottom: 33px;
            }
            .time {
              text-align: right;
              font-size: 14px;
              color: #ADB3C0;
            }
          }
        }
      }
    }


  }
</style>
