<template>
	<div class="container">

		<div class="main">
			<div class="title">产品介绍</div>
			<div class="title2">婚恋找对象新原力，用科技构建信任基石</div>
		</div>

		<div class="content">
			<div class="child">
				<div class="logo">
					<img src="@/assets/images/logo.png" alt="" />
				</div>
				<div class="title">
					蕙漾馨福婚恋平台
				</div>
				<div class="text">
					一款全新的短视频婚恋社区综合型APP，秉承着“铸造天下馨福婚姻”的理念，致力于帮助单身男女寻找合适的伴侣，成就美满的婚姻。
				</div>
				<div class="btn gradient-color" @click="toDetail(1)">
					了解更多
				</div>
			</div>
		</div>

		<div class="content2">
			<div class="child">
				<div class="wrap">
					<div class="logo">
						<img src="@/assets/images/logo_2.png" alt="" />
					</div>
					<div class="title">
						蕙漾馨福智慧未来餐厅
					</div>
					<div class="text">
						是为蕙漾馨福生态内利用先进的科技设施和智能化系统，为顾客提供更便捷、个性化和高效的餐饮体验的餐饮场所。智慧餐饮采用物联网技术和智能设备，以提升顾客就餐体验、提高餐厅运营效率和管理水平。
					</div>
					<div class="btn gradient-color" @click="toDetail(2)">
						了解更多
					</div>
				</div>
			</div>
		</div>

		<div class="content content3">
			<div class="child">
				<div class="logo">
					<img src="@/assets/images/logo_3.png" alt="" />
				</div>
				<div class="title">
					蕙漾馨福智慧健康酒店
				</div>
				<div class="text">
					利用先进的科技设施和智能化系统，为客人提供更便捷、舒适和个性化的住宿体验的酒店。智慧酒店采用物联网技术，将各种设备和设施连接到一起，实现智能化管理和控制。
				</div>
				<div class="btn gradient-color" @click="toDetail(3)">
					了解更多
				</div>
			</div>
		</div>

		<hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
	</div>
</template>

<script>
	import HyxfFooter from '@/components/footer'

	export default {
		name: 'JoinUs',
		data() {
			return {
				tabIndex: 1
			}
		},
		components: {
			HyxfFooter
		},
		methods: {
			toDetail(index) {
				console.log(index)
				if (index === 1) {
					this.$router.push({
						path: '/productPlatform'
					})
				} else if (index === 2) {
					this.$router.push({
						path: '/productRoom'
					})
				} else {
					this.$router.push({
						path: '/productHotel'
					})
				}
			},
			changeMenu(index) {
				this.$emit('childChangeMenu', index)
			},
		}


	};
</script>




<style lang="less" scoped>
	.container {
		width: 100%;

		.main {
			height: 100%;
			height: 500px;
			background-image: url('@/assets/images/product_1.png');
			background-size: cover;
			background-position: center;
			text-align: center;

			.title {
				padding-top: 174px;
				font-size: 60px;
				font-weight: bold;
				margin-bottom: 40px;
			}

			.title2 {
				font-size: 32px;
				opacity: 0.8;
			}
		}

		.content {
			width: 100%;
			height: 800px;
			background-image: url('@/assets/images/product_15.png');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			.child {
				width: 1280px;
				margin: 0 auto;

				.logo {
					width: 144px;
					height: 144px;
					padding-top: 156px;
					margin-bottom: 40px;

					img {
						width: 100%;
					}
				}

				.title {
					font-size: 32px;
					margin-bottom: 76px;
					font-weight: 600;
				}

				.text {
					width: 550px;
					line-height: 28px;
					margin-bottom: 80px;
				}

				.btn {
					width: 192px;
					height: 48px;
					color: #fff;
					text-align: center;
					line-height: 48px;
					border-radius: 200px;
					cursor: pointer;
				}
			}
		}

		.content2 {
			width: 100%;
			height: 800px;
			background-image: url('@/assets/images/product_16.png');
			background-size: cover;
			background-position: center;

			.child {
				width: 1280px;
				margin: 0 auto;
				position: relative;

				.wrap {
					position: absolute;
					right: 0;
				}

				.logo {
					width: 144px;
					height: 144px;
					padding-top: 156px;
					margin-bottom: 40px;

					img {
						width: 100%;
					}
				}

				.title {
					font-size: 32px;
					margin-bottom: 76px;
					font-weight: 600;
				}

				.text {
					width: 550px;
					line-height: 28px;
					margin-bottom: 80px;
				}

				.btn {
					width: 192px;
					height: 48px;
					color: #fff;
					text-align: center;
					line-height: 48px;
					border-radius: 200px;
					cursor: pointer;
				}
			}
		}

		.content3 {
			width: 100%;
			height: 800px;
			background-image: url('@/assets/images/product_17.png');
			background-size: cover;
			background-position: center;
		}


	}
</style>