<template>
	<div class="container">

		<div class="main">
			<div class="title">服务支持</div>
			<div class="title2">提供成熟的技术支持与贴心的体验服务</div>
		</div>

		<div class="content">
			<div class="middle-title">
				全方位客服体系
			</div>
			<div class="content-child">
				<div class="item" v-for="(item, index) of list" :key="index" @mouseenter="mouseenter(index)"
					@mouseleave="mouseleave">
					<div class="img" v-if="index === itemIndex">
						<img :src="item.urlSel" alt="" />
					</div>
					<div class="img" v-else>
						<img :src="item.url" alt="" />
					</div>
					<div class="name">{{ item.name }}</div>
					<div class="text">{{ item.text }}</div>
					<div class="btn gradient-color" v-if="item.btnName" @click="clickBtn(item.btnName)">
						{{ item.btnName }}
					</div>
					<div class="phone" v-else>
						{{ item.phone }}
					</div>
				</div>
			</div>
		</div>

		<div class="content2">
			<div class="middle-title">
				专属顾问服务
			</div>
			<div class="content-child">
				<div class="item" v-for="(item, index) of list2" :key="index">
					<div class="top">
						<img :src="item.url" alt="" />
						<div class="title">{{ item.title }}</div>
					</div>
					<div class="bottom">
						{{ item.text }}
					</div>
				</div>
			</div>
		</div>

		<hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
	</div>
</template>

<script>
	import HyxfFooter from '@/components/footer'

	export default {
		name: 'JoinUs',
		data() {
			return {
				itemIndex: 9999,
				list: [{
						name: '邮件客服',
						text: '我们将在一个工作日内进行回复',
						url: require('@/assets/images/service_2.png'),
						urlSel: require('@/assets/images/service_3.png'),
						phone: 'HYXF008@aliyun.com'
					},
					{
						name: '在线客服',
						text: '周一至周日 9:00-22:00',
						url: require('@/assets/images/service_4.png'),
						urlSel: require('@/assets/images/service_5.png'),
						btnName: '发起对话'
					},
					{
						name: '帮助中心',
						text: '在线帮助文档和FAQ',
						url: require('@/assets/images/service_6.png'),
						urlSel: require('@/assets/images/service_7.png'),
						btnName: '立即查看'
					},
					{
						name: '咨询电话',
						text: '周一至周日 9:00-22:00',
						url: require('@/assets/images/service_8.png'),
						urlSel: require('@/assets/images/service_9.png'),
						phone: '0755-33308356'
					}
				],
				list2: [{
						title: '专注体验',
						text: '蕙漾馨福专注用户体验，吸取反馈建议，不断优化打磨产品，为用户提供高效便捷的服务。',
						url: require('@/assets/images/service_10.png')
					},
					{
						title: '技术保障',
						text: '强大技术团队，为品牌项目提供网络安全、软硬件维护、故障排除修复、用户需求落地等一些列支持服务，保障项目质量。',
						url: require('@/assets/images/service_11.png')
					},
					{
						title: '智慧服务',
						text: '品牌项目搭载科技AI智能，软硬件设施有效结合，线上线下相互联通，实现“一机在手，全面掌握”。',
						url: require('@/assets/images/service_12.png')
					},
					{
						title: '馨福矩阵',
						text: '蕙漾馨福横跨健康、婚恋、养老、旅游、教育五大产业，提供优质品牌服务，打造全民“馨福”生活。',
						url: require('@/assets/images/service_13.png')
					},
					{
						title: '隐私安全',
						text: '我们注重您的隐私安全，承诺不以刺探、侵扰、泄露、公开等方式侵害用户隐私权。',
						url: require('@/assets/images/service_14.png')
					},
					{
						title: '蕙漾客服',
						text: '用户在使用产品的过程中存在任何疑问，均可联系平台客服进行反馈，我们将以最快的速度、最好的态度为您答疑解惑。',
						url: require('@/assets/images/service_15.png')
					}
				]
			}
		},
		components: {
			HyxfFooter
		},
		methods: {
			mouseleave() {
				this.itemIndex = 9999
			},
			mouseenter(index) {
				this.itemIndex = index
			},
			clickBtn(name) {
				console.log(name)
				if (name === '发起对话') {
					window.open(
						'https://hyxfwlkjszyxgs.qiyukf.com/client?k=07f0ea2c9050466f2b7866642e623001&wp=1&robotShuntSwitch=0'
						)
				} else if (name === '立即查看') {
					// window.open(href, '_blank');
				}
			},
			changeMenu(index) {
				this.$emit('childChangeMenu', index)
			},
		}


	};
</script>




<style lang="less" scoped>
	.container {
		width: 100%;

		.main {
			height: 100%;
			height: 500px;
			background-image: url('@/assets/images/service_1.png');
			background-size: cover;
			background-position: center;
			text-align: center;

			.title {
				padding-top: 174px;
				font-size: 60px;
				font-weight: bold;
				margin-bottom: 40px;
			}

			.title2 {
				font-size: 32px;
				opacity: 0.8;
			}
		}

		.middle-title {
			font-size: 40px;
			text-align: center;
			padding-top: 100px;
			padding-bottom: 60px;
		}

		.content2 {
			background-color: #F5F5F5;
			padding-bottom: 60px;

			.content-child {
				width: 1280px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.item {
					width: 320px;
					height: 157px;
					border-radius: 8px;
					background-color: #fff;
					padding: 40px 40px 20px;
					margin-bottom: 40px;

					.bottom {
						opacity: 0.6;
						line-height: 28px;
					}

					.top {
						display: flex;
						align-items: center;
						margin-bottom: 16px;

						img {
							width: 32px;
							height: 32px;
						}

						.title {
							font-size: 24px;
						}
					}
				}
			}
		}

		.content {
			background-color: #fff;
			padding-bottom: 100px;

			.content-child {
				width: 1280px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;

				.item {
					width: 290px;
					height: 398px;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;

					&:hover {
						background: linear-gradient(to bottom, rgb(22 100 255 / 0%), rgb(22 100 255 / 8%));
					}

					.img {
						width: 120px;
						height: 120px;
						margin-bottom: 40px;

						img {
							width: 100%;
						}
					}

					.name {
						font-size: 24px;
						margin-bottom: 30px;
					}

					.text {
						opacity: 0.6;
					}

					.phone {
						font-size: 24px;
						margin-top: 30px;
					}

					.btn {
						width: 192px;
						height: 48px;
						border-radius: 200px;
						line-height: 48px;
						text-align: center;
						color: #fff;
						margin-top: 40px;
						cursor: pointer;
					}
				}
			}

		}


	}
</style>