<template>
	<div class="container">
		<div class="main">
			<div class="title">
				公司简介
			</div>
			<div class="title2">
				业务涵盖五大产业，实现全人类“馨福”生活
			</div>
		</div>

		<div class="info">
			<div class="info-child">
				<div class="left">
					<div class="text">
						<span class="text-1">蕙漾馨福</span><span
							class="text-2">品牌2019年于四川乐山创立</span>，创始人张玉辉女士和汪洋先生在品牌创始之初，就立志打造含括健康、婚恋、养老、旅游、教育五大产业的多元化集团公司。在十九大习主席提出了实施健康中国战略，人民健康是民族昌盛和国家富强的重要标志，要完善国民健康政策，为人民群众提供全方位全周期健康服务的号召下，公司专注于健康研究健康咨询，帮助无数人和家庭解决了无数健康问题，受到了社会一致好评。
					</div>
					<div class="text">
						<div class="text-2">
							创始人-张玉辉女士-集团灵魂人物:
						</div>
						<span>集团的精神创造人物，健康领域的领军人物，她用行动证明生命不息，创业不止，蕙漾人为全球的馨福事业代代传承，持久奋斗和自我奉献。</span>
					</div>
					<div class="text">
						<div class="text-2">
							创始人-汪洋先生-集团灵魂人物:
						</div>
						<span>其个人在创造馨福人生、铸造馨福家庭方面潜心调研多年，紧跟时代的步伐，探索人性的本质，结合国情、政策研究，开创独有的婚恋观、家庭观、人生价值观，为集团健康婚恋事业的发展奠定了划时代的非凡意义</span>
					</div>
				</div>
				<div class="right"></div>
			</div>
		</div>

		<div class="honor">
			<div class="title">
				公司荣誉
			</div>
			<div class="honor-wrap">
				<div class="prev" @click="prev">

				</div>
				<div class="honot-chlid">
					<el-carousel ref="honor" height="448px" arrow="never" indicator-position="none">
						<el-carousel-item>
							<div class="zz-wrap">
								<div class="item" v-for="(item, index) of list" :key="index">
									<img :src="item.imgUrl" alt="" />
								</div>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div class="zz-wrap">
								<div class="item" v-for="(item, index) of list2" :key="index">
									<img :src="item.imgUrl" alt="" />
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>

				<div class="next" @click="next">

				</div>
			</div>

		</div>

		<hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
	</div>
</template>

<script>
	import HyxfFooter from '@/components/footer'
	export default {
		name: 'AboutUs',
		data() {
			return {
				isshow: false,
				list: [{
						imgUrl: require("../assets/images/zz_1.png")
					},
					{
						imgUrl: require("../assets/images/zz_2.png")
					},
					{
						imgUrl: require("../assets/images/zz_3.png")
					},
					{
						imgUrl: require("../assets/images/zz_4.png")
					},
					{
						imgUrl: require("../assets/images/zz_5.png")
					},
					{
						imgUrl: require("../assets/images/zz_6.png")
					},
					{
						imgUrl: require("../assets/images/zz_7.png")
					},
					{
						imgUrl: require("../assets/images/zz_8.png")
					},
				],
				list2: [{
						imgUrl: require("../assets/images/zz_9.png")
					},
					{
						imgUrl: require("../assets/images/zz_10.png")
					},
					{
						imgUrl: require("../assets/images/zz_11.png")
					},
					{
						imgUrl: require("../assets/images/zz_12.png")
					},
					{
						imgUrl: require("../assets/images/zz_13.png")
					},
					{
						imgUrl: require("../assets/images/zz_14.png")
					},
				],
			};
		},
		components: {
			HyxfFooter
		},
		methods: {
			changeMenu(index) {
				this.$emit('childChangeMenu', index)
			},
			setActiveItem() {
				console.log(2)
			},
			prev() {
				this.$refs.honor.prev()
			},
			next() {
				this.$refs.honor.next()
			},
		},
	};
</script>

<style lang="less" scoped>
	.container {
		width: 100%;

		.main {
			width: 100%;
			height: 500px;
			background-image: url('@/assets/images/aboutus_1.png');
			background-size: cover;
			background-position: center;
			text-align: center;

			.title {
				padding-top: 174px;
				margin-bottom: 40px;
				font-size: 60px;
				font-weight: bold;
			}

			.title2 {
				font-size: 32px;
				opacity: 0.8;
			}
		}

		.honor {
			padding: 100px 0;
			background-color: #F5F5F5;

			.title {
				font-size: 40px;
				text-align: center;
				margin-bottom: 80px;
			}

			.honor-wrap {
				display: flex;
				align-items: center;
				width: 1480px;
				margin: 0 auto;

				.prev {
					width: 60px;
					height: 60px;
					background-image: url('@/assets/images/aboutus_2.png');
					background-size: cover;
					background-position: center;
					cursor: pointer;

					&:hover {
						background-image: url('@/assets/images/aboutus_4.png');
					}
				}

				.next {
					width: 60px;
					height: 60px;
					background-image: url('@/assets/images/aboutus_3.png');
					background-size: cover;
					background-position: center;
					cursor: pointer;

					&:hover {
						background-image: url('@/assets/images/aboutus_5.png');
					}
				}

				.honot-chlid {
					width: 1280px;
					margin: auto;

					.zz-wrap {
						width: 100%;
						height: 100%;
						display: flex;
						flex-wrap: wrap;

						.item {
							width: 290px;
							height: 204px;
							margin-bottom: 40px;
							margin-right: 40px;

							&:nth-child(4n) {
								margin-right: 0px;
							}

							img {
								width: 100%;
							}
						}
					}
				}
			}
		}

		.info {
			background-color: #fff;
			padding: 120px 0 100px;

			.info-child {
				width: 1280px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;

				.left {
					width: 620px;

					.text {
						line-height: 28px;
						margin-bottom: 40px;

						.text-1 {
							font-size: 24px;
							font-weight: bold;
						}

						.text-2 {
							font-weight: bold;
						}
					}
				}

				.right {
					width: 620px;
					height: 451px;
					background-image: url('@/assets/images/joinus_2.png');
					background-size: cover;
					background-position: center;
				}
			}
		}
	}
</style>