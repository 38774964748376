<template>
  <div class="container">
    <div class="content">
      <div class="title">
        汪洋先生带领团队研发的婚恋社交平台即将上线，现场活动十万礼金等你来领取！
      </div>
      <div class="info">
        <div class="time">
          <img src="@/assets/images/news_5.png" alt="" />
          <span>2023年11月13日</span>
        </div>
        <div class="text-content">
          <div class="img">
            <img src="@/assets/images/news_1.png" alt="" />
          </div>
          <div class="text">
            2023年11月，蕙漾馨福网络科技（深圳）有限公司创始人汪洋先生受邀参加了江苏卫视举办的闪光创始人节目，在节目上，蕙漾馨福创始人汪洋先生详细的阐述了蕙漾馨福网络科技（深圳）有限公司的商业模式与功能模块，这次的讲解，汪洋先生所述话语得到了现场诸多人士的高度赞赏和评价，在节目上获取了巨大成功。
          </div>
          <div class="text">
            在闪光创始人节目中，汪洋先生以新时代创业家的精神领袖身份出现。他用真挚的语言，深情地讲述着创业路上的点点滴滴，倾诉着创办蕙漾馨福的初心、奋斗过程以及企业在科技领域的探索和发展。他的言语生动而富有感染力，触动了在场的每一位观众和创业者。那一刻，汪洋先生所经历的创业路上的所有劳累与辛苦，都化成了激励人心的力量，成为了一面指引创业者前行的旗帜。
          </div>
          <div class="text">
            2024年1月由汪洋先生带领下的团队研发的婚恋社交综合平台-蕙漾馨福APP将正式上线，届时会举办大型线下活动与游戏，还有十万礼金可以领取，欢迎各位莅临现场参与蕙漾馨福活动现场，共赴蕙漾馨福新道路。
          </div>
        </div>
      </div>
    </div>
    <hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
  </div>
</template>

<script>
  import HyxfFooter from '@/components/footer'
  export default {
    name: 'newsDetail',
    data() {
      return {

      }
    },
    components: {
      HyxfFooter
    },
    methods: {

    }
  }
</script>

<style lang="less" scoped>
  .container {
    background-color: #fff;

    .content {
      width: 1280px;
      margin: 0 auto;
      padding: 100px 0;
      .title {
        font-size: 32px;
        margin-bottom: 40px;
      }

      .info {
        .time {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
          }

          span {
            margin-left: 8px;
            color: #676B72;
          }
        }

        .text-content {
          margin-top: 60px;
          .img {
            width: 750px;
            height: 100%;
            margin: 0 auto;
            margin-bottom: 40px;
          }
          img {
            width: 100%;
          }
          .text {
            line-height: 28px;
            margin-bottom: 40px;
            text-indent: 2em;
          }
        }
      }
    }
  }
</style>
