<template>
	<div id="app">
		<hyxf-header @changeMenu="changeMenu" :index="active"></hyxf-header>

		<hyxf-footer @changeMenu="changeMenu" v-if="companyprofile"></hyxf-footer>

		<div class="APP-MAIN">
			<keep-alive>
				<router-view></router-view>
			</keep-alive>
		</div>

	</div>
</template>

<script>
	import HyxfHeader from '@/components/header'
	import HyxfFooter from '@/components/footer'

	export default {
		name: 'OfficialApp',
		data() {
			return {
				isactve: '1',
				active: '1',
				isshow: false,
				showCarousel: false,
				carouselHight: undefined,
				newsdetail: false,
				contactus: false,
				companyprofile: false,
				storageMenu: '',
				clientWidth: 0
			};
		},
		components: {

			HyxfHeader,
			HyxfFooter,

		},

		watch: {
			storageMenu(val) {
				console.log(val, '监听')
				this.active = val
			}
		},
		created() {
			this.clientWidth = document.body.clientWidth
			// 监听浏览器宽度
			this.windowWidth(document.documentElement.clientWidth)

			window.onresize = (e) => {
				console.log()
				this.clientWidth = document.body.clientWidth
				this.windowWidth(this.clientWidth)
			};
		},
		mounted() {
			this.storageMenu = sessionStorage.getItem('menuIndex')
		},

		methods: {
			windowWidth(value) {
				// 官网安全区域为1280px
				if (value < 1279) {
					// 跳转去移动端
					window.location.href = 'https://www.hyxf5188.com/mobile'
				}
			},
			newsDetail(index) {
				this.active = 'newsDetail'
				console.log(index, 'dwandklwa')
			},
			changeMenu(index) {
				this.active = index

			},

			toDetail() {
				// 跳转详情
				this.active = '3'
			},

			gocarousel(type) {
				this.isactve = type
				this.$refs.carousels.setActiveItem(type)
			},
			myClick(item) {
				this.active = '4'
				if (item === 1) {
					this.contactus = false
					this.companyprofile = true
					this.newsdetail = false
				}
				if (item === 4) {
					this.contactus = false
					this.companyprofile = false
					this.newsdetail = true
				}
				if (item === 5) {
					this.newsdetail = false
					this.companyprofile = false
					this.contactus = true
				}
			},
			myClick2() {
				this.active = '4'
				this.contactus = false
				this.companyprofile = true
				this.newsdetail = false
			},
			myClick3(item) {
				this.active = item,
					this.newsdetail = false
				this.companyprofile = false
				this.contactus = false
			},
		},
	};
</script>
<style>
	body {
		margin: 0px;
		padding: 0px;
		height: 100vh;


		/* padding: 20; */
	}
</style>
<style lang="less" scoped>
	@import url("assets/css/common.css");

	.APP-MAIN {
		padding-top: 80px;
	}

	#app {
		margin: 0;
		padding: 0;
		height: 100%;
	}

	.my:hover>.myList {
		width: 144px;
		height: 288px;
		background: #1B2B4F;
		border-radius: 0px 0px 0px 0px;
		opacity: 0.8;
		position: absolute;
		display: block;
		bottom: -288px;
		left: -35px;
		z-index: 11;
	}

	.myList {
		display: none;
		width: 144px;
		// transition: height 1s;

	}
</style>