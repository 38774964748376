<template>
	<div class="container" @wheel="handlePageScroll">
		<!-- 这里是轮播图 -->
		<div id="carousel">
			<el-carousel trigger="click" :height="carouselHight" :autoplay=false direction="vertical"
				indicator-position="none" ref="carousels">
				<el-carousel-item name="1">
					<div class="lgm-index-1">
						<div class="lgm-index-2-child">
							<el-carousel :height="carouselHight" arrow="never" :autoplay="false">
								<el-carousel-item>
									<div class="lgm-index-2-child-2 videoBj">
										<div class="lgm-index-1-child flex">
											<div>
												<div class="text1-2">
													蕙漾馨福
												</div>
												<div class="text1-1-2">铸造天下馨福婚姻！</div>
											</div>
											<div class="videoModules">
												<video class="video-js" :width="622" :height="351"
													:controls="true" :id="playerId"></video>
											</div>
										</div>
									</div>
								</el-carousel-item>
								<el-carousel-item>
									<div class="lgm-index-1-child">
										<div class="text1" style="margin-top: 100px;">
											<!-- 会员领结婚证可获 -->
											蕙漾馨福
											<!-- 	<span style="font-size: 80px;color: #1664FF ;">10万</span>礼金 -->
										</div>
										<div class="text1-1">铸造天下馨福婚姻！</div>
										<!-- <div class="text2">首次即可领取10%</div> -->
									</div>
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>
				</el-carousel-item>


				<el-carousel-item name="2">
					<div class="lgm-index-2">
						<div class="lgm-index-2-child">
							<el-carousel :height="carouselHight" arrow="never" :autoplay="false">
								<el-carousel-item>
									<div class="lgm-index-2-child-1 common">
										<div class="lgm-container">
											<div class="title">蕙漾馨福婚恋平台</div>
											<div class="logo"></div>
											<div class="text">
												蕙漾馨福婚恋APP
											</div>
											<div class="text2">
												蕙漾馨福是一款全新的短视频婚恋社区综合型APP。我们致力于解决全社会单身群体的婚姻难题，让中国单身人群变少、让中国馨福家庭增多、让中国人口负增逆转，打造全民和谐幸福的美好生活。
											</div>
											<div class="text2">
												同时，我们还是一款短视频创作分享平台，主打恋爱经验分享、婚姻相关内容展示、不同地域的婚姻文化差异等，营造浓厚的婚姻话题氛围，鼓励单身人群恋爱结婚。
											</div>
										</div>

									</div>
								</el-carousel-item>

								<el-carousel-item>
									<div class="lgm-index-2-child-2 common">
										<div class="lgm-container">
											<div class="title">蕙漾馨福婚恋平台</div>
											<div class="logo"></div>
											<div class="text">
												蕙漾馨福婚恋APP
											</div>
											<div class="text2">
												<!-- 针对已婚人群，蕙漾馨福APP提供家庭号功能，将夫妻双方账号合并为家庭账号，用户可使用家庭账号发布生活趣事、夫妻生活、情感教育等短视频内容，平台给予流量扶持和创作激励，帮助已婚用户赚取创作金。 -->
												针对已婚人群，蕙漾馨福APP提供家庭号功能，将夫妻双方账号合并为家庭账号，用户可使用家庭账号发布生活趣事、夫妻生活、情感教育等短视频内容，平台给予流量扶持和创作激励。
											</div>
											<!-- <div class="text2">
												此外， 用户在蕙漾馨福婚恋平台结婚领证，满足平台规则的，还可获得平台给予的10万元新婚“筑巢”礼金，更有参与奔驰豪车抽奖的资格。
											</div> -->
										</div>
									</div>
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>

				</el-carousel-item>

				<el-carousel-item name="3">
					<div class="lgm-index-2 lgm-index-3">
						<div class="lgm-index-2-child common">
							<el-carousel :height="carouselHight" arrow="never">
								<el-carousel-item>
									<div class="lgm-index-3-child-1 common">
										<div class="lgm-container lgm-container-wrap">
											<div class="left">
											</div>
											<div class="right">
												<div class="title2">蕙漾馨福婚恋APP</div>
												<div class="code"></div>
												<div class="lgm-text2">下载方式</div>
												<div class="lgm-text3">
													蕙漾馨福婚恋APP支持Android 和iOS、鸿蒙OS 平台,
													客户端的用户均可以通过各大应用程序商店上搜索并下载，App的本地体验更好，用户用起来更加丝滑，更多特色待你去发现。
												</div>
											</div>
										</div>
									</div>
								</el-carousel-item>

								<el-carousel-item>
									<div class="lgm-index-3-child-2 common">
										<div class="lgm-container lgm-container-wrap">
											<div class="left">
											</div>
											<div class="right">
												<div class="title2">蕙漾馨福公众号</div>
												<div class="code code-gzh"></div>
												<div class="lgm-text2">扫码关注</div>
												<div class="lgm-text3">
													蕙漾馨福是一家专注于相亲婚恋服务的综合性平台，坚持“铸造天下馨福婚姻”理念，为有结婚需求的单身人群提供高效、便捷的婚恋服务。
												</div>
											</div>
										</div>
									</div>
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>

				</el-carousel-item>

				<el-carousel-item name="4">
					<div class="lgm-index-4">
						<div class="lgm-index-4-child">
							<div class="title">支持与服务</div>
							<div class="text">
								蕙漾馨福竭诚为客户提供全方位的服务：专业团队的技术支持，提供软硬件产品与网络安全的解决方案；产品合理设计功能帮助用户高效便捷满足需求，提供贴心的品质服务；品牌信誉保障为用户信息安全与售后体验负责。
							</div>
							<div class="btn" @click="toDetail">了解详情</div>

							<!-- <div class="support">
                <div class="support-tit">支持与服务</div>
                <div class="support-text">
                  蕙漾馨福创新科技为您提供多类型多渠道的服务支持以满足不同用户的支持需求。若您遇到使用问题，可以通过工单、热线、支持计划、专项服务等渠道联系我们，我们助您安心。
                </div>
                <div class="support-btn" style="cursor: pointer;" @click="myClick3('3')">了解详情</div>
              </div> -->
						</div>

					</div>
				</el-carousel-item>

				<el-carousel-item name="5">
					<div class="lgm-index-5">
						<div class="child">
							<div class="top">
								<img src="../assets/new.png" alt="">
								<div class="title">新闻动态</div>
							</div>

							<div class="swiper">

								<el-carousel :interval="444000" type="card" height="612px">
									<el-carousel-item v-for="(item, index) in newsList" :key="index">
										<div class="swiper-item">
											<div class="title">
												{{ item.title }}
											</div>
											<div class="time">
												{{ item.time }}
											</div>
											<div class="text">
												{{ item.text }}
											</div>
											<div class="img">
												<img :src="item.url" alt="" />
											</div>
										</div>
									</el-carousel-item>
								</el-carousel>
							</div>

						</div>
					</div>

				</el-carousel-item>
				<el-carousel-item name="6">
					<div class="lgm-index-6">
						<div class="lgm-index-6-child">
							<div class="title">合作伙伴</div>
							<div class="title2">正与众多客户一起创造更多价值</div>
							<div class="hzhb">
								<img src="../assets/img28.png" alt="" class="item">
								<img src="../assets/img29.png" alt="" class="item">
								<img src="../assets/img30.png" alt="" class="item">
								<img src="../assets/img31.png" alt="" class="item">
								<img src="../assets/img32.png" alt="" class="item">
								<img src="../assets/img33.png" alt="" class="item">
								<img src="../assets/img34.png" alt="" class="item">
								<div class="item more">
									<div class="more-item"></div>
									<div class="more-item"></div>
									<div class="more-item"></div>
								</div>
							</div>
						</div>
					</div>

				</el-carousel-item>

				<el-carousel-item name="7">
					<div class="footer-wrap">
						<div class="footer">

							<div class="top">
								<div class="left">
									<div class="img"></div>
								</div>
								<div class="right">
									<p>网站导航</p>
									<p>行业资讯</p>
									<p>关注我们</p>
								</div>
							</div>

							<div class="bottom">
								<div class="left">
									<div class="item">
										<img src="../assets/images/product_9.png" alt="">
										<div class="text">400咨询</div>
										<div class="info">400-152-5188</div>
										<div class="text">座机号码</div>
										<div class="info">0755-33308356</div>
									</div>

									<div class="item">
										<img src="../assets/images/product_10.png" alt="">
										<div class="text">技术支持QQ</div>
										<div class="info">3679904950</div>
										<div class="text">
											(工作日10:00-19:00)
										</div>
									</div>

									<div class="item">
										<img src="../assets/images/product_11.png" alt="">
										<div class="text">产品建议邮箱</div>
										<div class="info">HYXF008@aliyun.com</div>
									</div>
								</div>

								<div class="right">
									<div class="item-wrap">
										<p @click="jumpPage('1')">网站首页</p>
										<p @click="jumpPage('2')">产品介绍</p>
										<p @click="jumpPage('3')">服务支持</p>
										<p @click="jumpPage('4')">关于我们</p>
										<p @click="jumpPage('5')">加入我们</p>
									</div>
									<div class="item-wrap">
										<p>智慧酒店</p>
										<p>数据分析</p>
										<p>婚恋市场</p>
										<p>数据治理</p>
										<p>智慧餐饮</p>
									</div>
									<div class="code">
										<img src="../assets/images/product_12.png" alt="" />
									</div>
								</div>
							</div>
						</div>
						<div class="icp">
							Copyright © www.hyxf5188.com, All Rights Reserved. <a style="text-decoration: none;color: #fff;" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2023130472号</a> 蕙漾馨福——铸造天下馨福婚姻
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>

			<!-- 按钮 -->
			<div class="tagList">
				<div class="tagItem" :class="{ tagItem_actve: isactve === '1' }" @click="gocarousel('1')"></div>
				<div class="tagItem" :class="{ tagItem_actve: isactve === '2' }" @click="gocarousel('2')"></div>
				<div class="tagItem" :class="{ tagItem_actve: isactve === '3' }" @click="gocarousel('3')"></div>
				<div class="tagItem" :class="{ tagItem_actve: isactve === '4' }" @click="gocarousel('4')"></div>
				<div class="tagItem" :class="{ tagItem_actve: isactve === '5' }" @click="gocarousel('5')"></div>
				<div class="tagItem" :class="{ tagItem_actve: isactve === '6' }" @click="gocarousel('6')"></div>
				<div class="tagItem" :class="{ tagItem_actve: isactve === '7' }" @click="gocarousel('7')"></div>
			</div>

		</div>
	</div>
</template>

<script>
	import videojs from "video.js"
	export default {
		name: 'NewsDetail',
		data() {
			return {
				playerId: 'video',
				newsList: [{
						title: '蕙漾馨福婚恋启航 助力扬起中国婚恋行业健康发展的风帆',
						time: '2024.01.06',
						url: require('@/assets/images/news_6.png'),
						text: '2024年1月6日，蕙漾馨福启动仪式暨春雷行动新闻发布会在深圳欢乐海岸举办。蕙漾馨福品牌自启航仪式后上线APP，将积极为提升结婚率、改善我国目前人口负增长问题做出不懈努力。原国家民政部副部长、国家民族事务委员会常务副主任陈虹，中共中央统战部原局长曹虹冰，中共中央对外联络部原副部长马文普，总政治部老干部服务管理局原政委、中国扶贫开发协会宣教委原秘书长马昌奎，蕙漾馨福创始人张玉辉、蕙漾馨福董事长&创始人汪洋等领导嘉宾出席了本次活动。'
					},
					{
						title: '蕙漾馨福正式启航 60余家媒体争相报道',
						time: '2024.01.06',
						url: require('@/assets/images/news_7.png'),
						text: '蕙漾馨福品牌启航仪式于2024年1月6日在深圳海岸城成功举行。本次活动吸引60余家省市级以上媒体报道。在主流媒体发布新闻后2小时，蕙漾馨福本次线下活动引起全网自媒体创作者争相转发，霸榜深圳同城热搜第一名。'
					},
					{
						title: '蕙漾馨福创始人汪洋先生受邀参加江苏卫视闪光创始人节目！',
						time: '2023.11.13',
						url: require('@/assets/images/news_1.png'),
						text: '汪洋先生参加江苏卫视闪光创始人节目，详细阐述蕙漾馨福网络科技的商业模式和功能模块，现场观众和创业者纷纷表示赞赏。2024年1月，蕙漾馨福APP将上线，并举办大型线下活动。'
					},
					{
						title: '蕙漾馨福技术团队与阿里巴巴合作共谋亿万用户服务架构！',
						time: '2023.12.19',
						url: require('@/assets/images/news_2.png'),
						text: '12月19日，蕙漾馨福与阿里巴巴技术团队交流，探讨服务架构挑战。阿里巴巴团队分享亿万用户服务经验，解决蕙漾馨福技术问题，为APP上线后应对千万用户涌入提供解决思路。'
					},
					{
						title: '2023年蕙漾馨福沙龙深圳召开，2024春雷行动启航仪式即将举行！',
						time: '2023.10.28',
						url: require('@/assets/images/news_3.png'),
						text: '11月，江苏卫视全程报道的蕙漾馨福沙龙在深圳市招商前海经贸中心举行，公司创始人汪洋详细描述战略成果，表达愿景。1月，在深圳欢乐海岸将举行蕙漾馨福春雷行动。'
					},
					{
						title: '蕙漾馨福是否触碰了某些人的“蛋糕”？',
						time: '2024.01.08',
						url: require('@/assets/images/news_8.png'),
						text: '2024年1月6日，蕙漾馨福APP正式上线，一时引起轩然大波。而就在这一片火热的盛景中，蕙漾馨福的官方服务后台却遭受了数十次有组织有预谋的针对性攻击，好在蕙漾馨福拥有坚实可靠的维护团队，将这些不怀好意的针对性攻击尽数化解，保障了广大用户的信息安全与相关权益。而针对这一事件，我们不得不提出一个疑问：蕙漾馨福的横空出世，是否触碰到了某些人的“蛋糕”？'
					}
				],
				isactve: '1',
				active: '1',
				isshow: false,
				showCarousel: false,
				carouselHight: undefined,
				newsdetail: false,
				contactus: false,
				companyprofile: false,
				ifFull: true,
				isScrollChange: true,
				changeNum: 0,
				prevPageNum: 0,
				nextPageNum: 0,
			}
		},
		mounted() {

			this.initVideo()

			let myElement = document.getElementById("carousel").offsetHeight


			if (myElement > 830 && myElement < 1400) {
				this.carouselHight = myElement + 'px' || '0px'
				this.ifFull = true
			} else {
				this.carouselHight = '937px'
				this.ifFull = false
			}
			this.showCarousel = true;

			window.addEventListener('scroll', this.handleScroll, true);
		},
		methods: {

			initVideo() {
				this.$nextTick(() => {
					const player = videojs(this.playerId, {
						autoplay: false
					});
					player.src(
						'https://1500028630.vod-qcloud.com/cbb39582vodsh1500028630/4af9af63388912588653454690/0l2T5yEuhnAA.mp4'
					);
					this.$nextTick(() => {
						player.on('playing', () => {
							setTimeout(() => {
								player.requestFullscreen()
							})
						})
					})
				})
			},
			setTime() {
				setTimeout(() => {
					this.isScrollChange = true
				}, 700)
			},
			handlePageScroll(e) {
				if (this.ifFull && this.isScrollChange) {
					this.isScrollChange = false

					let num = 1
					console.log(e.deltaY, '下滑')
					if (e.deltaY > 0) {
						// 下滑
						num = this.isactve * 1 + 1
						if (num === 8) {
							this.isactve = '1'
						} else {
							this.isactve = String(num)
						}
					} else {
						// 上滑
						num = this.isactve * 1 - 1
						if (num === 0) {
							this.isactve = '7'
							num = 7
						} else {
							this.isactve = String(num)
						}
					}


					this.gocarousel(this.isactve)
					this.setTime()
				}

				if (!this.ifFull && this.isScrollChange) {
					let num = 1
					let scrollTop = document.documentElement.scrollTop; //滚动高度
					let clientHeight = document.documentElement.clientHeight; //可视高度
					let scrollHeight = document.documentElement.scrollHeight; //内容高度
					let total = scrollTop + clientHeight

					if (e.deltaY > 0) {
						// 下滑
						this.prevPageNum = 0
					} else {
						// 上滑
						this.nextPageNum = 0
					}

					if (scrollTop === 0 && e.deltaY < 0) {
						// 上滑
						this.nextPageNum = 0
						this.prevPageNum += 1
						console.log(this.prevPageNum, 'this.prevPageNum')
						this.isScrollChange = false
						this.setTime()
						if (this.prevPageNum === 2) {
							num = this.isactve * 1 - 1
							if (num === 0) {
								this.isactve = '7'
								num = 7
							} else {
								this.isactve = String(num)
							}

							this.gocarousel(this.isactve)
							this.prevPageNum = 0
						}
					}


					if (total === scrollHeight && e.deltaY > 0) {
						// 下滑
						this.nextPageNum += 1
						this.prevPageNum = 0
						console.log(this.nextPageNum, 'this.nextPageNum')
						this.isScrollChange = false
						this.setTime()
						if (this.nextPageNum === 2) {
							// 下滑
							num = this.isactve * 1 + 1
							if (num === 8) {
								this.isactve = '1'
							} else {
								this.isactve = String(num)
							}
							this.gocarousel(this.isactve)
							this.nextPageNum = 0
						}
					}
				}
			},
			handleScroll() {
				return
				let scrollTop = document.documentElement.scrollTop; //滚动高度
				let clientHeight = document.documentElement.clientHeight; //可视高度
				let scrollHeight = document.documentElement.scrollHeight; //内容高度

				let total = scrollTop + clientHeight

				this.isScrollChange = false
				let num = 1

				if (total === scrollHeight) {
					// 下滑
					num = this.isactve * 1 + 1
					if (num === 8) {
						this.isactve = '1'
					} else {
						this.isactve = String(num)
					}
					this.gocarousel(this.isactve, true)
					this.setTime()
				}

				if (scrollTop === 0) {
					// 上滑
					num = this.isactve * 1 - 1
					if (num === 0) {
						this.isactve = '7'
						num = 7
					} else {
						this.isactve = String(num)
					}
					this.gocarousel(this.isactve)
					this.setTime()
				}


				// console.log(total, scrollHeight);
				console.log("滚动高度", scrollTop);
				// console.log("可视高度", clientHeight);
				// console.log("内容高度", scrollHeight);
			},
			jumpPage(index) {
				this.active = index
				switch (index) {
					case '1':
						this.$router.push({
							name: 'home'
						}, () => {
							console.log('')
						})
						break
					case '2':
						this.$router.push({
							name: 'product'
						}, () => {
							console.log('')
						})
						break
					case '3':
						this.$router.push({
							name: 'service'
						}, () => {
							console.log('')
						})
						break
					case '4':
						this.$router.push({
							name: 'aboutUs'
						}, () => {
							console.log('')
						})
						break
					case '5':
						this.$router.push({
							name: 'joinUs'
						}, () => {
							console.log('')
						})
						break

				}
			},
			gocarousel(type, scrollTop) {
				this.isactve = type
				this.$refs.carousels.setActiveItem(type)
			},
			toDetail() {
				this.$router.push({
					path: '/service?active=3'
				})
			}
		},

	}
</script>

<style lang="less" scoped>
	.flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	::v-deep .el-carousel__button {
		background-color: #1664FF !important;
	}

	.videoBj {
		background-image: url('../assets/images/newYearBj.png') !important;
		height: 100%;
	}

	.footer-wrap {
		width: 100%;
		height: 100%;
		background-color: #1B2B4F;
		background-image: url('@/assets/images/footer.png');
		background-size: cover;
		position: relative;

		.icp {
			width: 100%;
			height: 76px;
			line-height: 76px;
			text-align: center;
			color: #fff;
			font-size: 14px;
			opacity: 0.5;
			position: absolute;
			left: 0;
			bottom: 0;
			border-top: 1px solid rgb(255 255 255 / 8%);
		}

		.footer {
			width: 1280px;
			height: 100%;
			margin: 0 auto;

			.top {
				padding-top: 300px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.08);
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.left {
					.img {
						width: 164px;
						height: 48px;
						background-image: url('../assets/images/product_8.png');
						background-size: cover;
						background-position: center;

					}
				}

				.right {
					display: flex;
					justify-content: space-between;

					p {
						margin: 0 40px;
						font-size: 18px;
						color: #fff;

						&:last-child {
							margin-right: 48px;
						}
					}
				}
			}

			.bottom {
				margin-top: 33px;
				display: flex;

				.left {
					display: flex;
					width: 856px;
					justify-content: space-between;

					.item {
						&:last-child {
							margin-right: 118px;
						}

						img {
							width: 44px;
							height: 44px;
							margin-bottom: 20px;
						}

						.text {
							font-size: 14px;
							color: #fff;
							opacity: 0.5;
						}

						.info {
							margin-top: 5px;
							font-size: 24px;
							color: #fff;
							margin-bottom: 30px;
							opacity: 0.5;
						}
					}
				}

				.right {
					flex: 1;
					display: flex;
					justify-content: space-between;

					.item-wrap {
						// margin-right: 50px;
						opacity: 0.5;

						p {
							font-size: 14px;
							color: #fff;
							margin-bottom: 30px;
							margin-top: 0;
							cursor: pointer;
						}
					}

					.code {
						width: 120px;
						height: 150px;

						img {
							width: 100%;
						}
					}
				}
			}

		}
	}

	.trendsi_tit {
		position: absolute;
		top: 100px;
		left: 320px;

	}

	.trendsi_tit_text1 {
		width: 119px;
		height: 36px;
		margin-right: 22px;
	}

	.trendsi_tit_text2 {
		width: 160px;
		height: 36px;
		font-size: 40px;
		font-family: Source Han Sans SC, Source Han Sans SC;
		font-weight: 400;
		color: #1B2B4F;
		line-height: 36px;
	}



	.btn2 {
		width: 192px;
		height: 48px;
		background: linear-gradient(270deg, rgba(22, 100, 255, 0.5) 0%, #1664FF 100%);
		border-radius: 500px 500px 500px 500px;
		opacity: 1;
		text-align: center;
		line-height: 48px;
		font-size: 16px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
	}

	.btn1 {
		width: 192px;
		height: 48px;
		background: rgb(255 255 255 / 20%);
		border-radius: 500px 500px 500px 500px;
		opacity: 1;
		text-align: center;
		line-height: 48px;
		font-size: 16px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
	}

	.lgm-index-1 {
		width: 100%;
		height: 100%;
		background-image: url('../assets/maskgroup.png');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		::v-deep .el-carousel__indicators--horizontal {
			bottom: 40px;
			left: calc((100vw - 1212px) / 2);

			.el-carousel__button {
				width: 40px;
				height: 4px;
			}
		}

		.lgm-index-1-child {
			width: 1280px;
			margin: 0 auto;

			.text1 {
				font-size: 60px;
				font-weight: bold;
				color: #FFFFFF;
				padding-top: 227px;
				margin-bottom: 20px;
			}

			.text1-1 {
				font-size: 60px;
				// font-weight: bold;
				color: #FFFFFF;
				// padding-top: 227px;
				margin-bottom: 40px;
			}

			.text2 {
				font-size: 32px;
				font-weight: 400;
				color: #FFFFFF;
			}

			.text1-2 {
				font-weight: bold;
				padding-top: 227px;
				margin-bottom: 20px;
				font-size: 60px;
				margin-bottom: 40px;
				background-image: -webkit-linear-gradient(bottom, #FADD83, #DFC558);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			.text1-1-2 {
				font-size: 60px;
				margin-bottom: 40px;
				background-image: -webkit-linear-gradient(bottom, #FADD83, #DFC558);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			.btn {
				margin-top: 100px;
			}
		}

		.videoModules {
			margin-top: 233px;
			width: 662px;
			height: 391px;
			background-image: url('../../src/assets/images/newYearBorder.png');
			background-size: cover;

			.video-js {
				margin-left: 20px;
				margin-top: 20px;
			}
		}

	}

	.lgm-index-2 {
		width: 100%;
		height: 100%;

		::v-deep .el-carousel__indicators--horizontal {
			bottom: 40px;
			left: calc((100vw - 1212px) / 2);

			.el-carousel__button {
				width: 40px;
				height: 4px;
			}
		}

		.lgm-index-2-child {
			.common {
				background-size: cover;
				background-position: center;
				height: 100%;

				.title {
					font-size: 40px;
					color: #020814;
					padding-top: 100px;
					text-align: center;
					margin-bottom: 120px;
				}

				.logo {
					width: 144px;
					height: 144px;
					background-image: url('../assets/images/logo.png');
					background-size: cover;
					background-position: center;
					margin-bottom: 60px;
				}

				.text {
					color: #020814;
					font-size: 32px;
					font-weight: bold;
					margin-bottom: 40px;
				}

				.text2 {
					width: 550px;
					margin-bottom: 30px;
					line-height: 28px;
				}
			}

			.lgm-index-2-child-1 {
				background-image: url('../assets/images/product_3.png');

			}

			.lgm-index-2-child-2 {
				background-image: url('../assets/images/product_2.png');
			}
		}
	}

	.common {
		.lgm-container-wrap {
			display: flex;
			justify-content: space-between;

			.left {
				flex: 1;
			}

			.right {
				width: 550px;

				.title2 {
					font-size: 40px;
					padding-top: 100px;
				}

				.code {
					width: 307px;
					height: 307px;
					background-image: url('../assets/images/product_6.png');
					background-size: cover;
					background-position: center;
					margin-top: 70px;
					margin-bottom: 20px;
				}

				.lgm-text2 {
					font-size: 32px;
					text-align: center;
					width: 307px;
					margin-bottom: 60px;
				}

				.lgm-text3 {
					line-height: 30px;
				}
			}
		}
	}

	.lgm-index-3 {
		.lgm-index-3-child-1 {
			background-image: url('../assets/images/product_4.png');
		}

		.lgm-index-3-child-2 {
			background-image: url('../assets/images/product_5.png');

			.code-gzh {
				background-image: url('../assets/images/product_20.png') !important;
			}
		}
	}

	.lgm-index-5 {
		width: 100%;
		height: 100%;
		background-image: url('../assets/images/index_1.png');
		background-size: cover;
		background-position: center;

		::v-deep .el-carousel__item--card {
			width: 440px !important;
			margin: 0 100px;
		}

		.child {
			width: 1280px;
			margin: 0 auto;

			.top {
				display: flex;
				align-items: center;
				margin-top: 100px;
				margin-bottom: 80px;

				.title {
					font-size: 40px;
					margin-left: 22px;
				}

				img {
					width: 114px;
					height: 39px;
				}
			}

			.swiper-item {
				background-color: #fff;
				padding: 40px;
				width: 358px;
				height: 477px;
				border-radius: 8px;
				box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
				border: 1px solid #eee;

				.title {
					font-size: 24px;

				}

				.time {
					font-size: 14px;
					text-align: center;
					color: #1B2B4F;
					opacity: 0.6;
					margin-top: 19px;
				}

				.text {
					color: #1B2B4F;
					opacity: 0.6;
					font-size: 14px;
					margin-top: 19px;
					margin-bottom: 22px;
				}

				.img {
					width: 338px;
					height: 202px;

					img {
						width: 100%;
					}
				}
			}

			.swiper {
				width: 1280px;
				margin: 0 auto;

			}
		}
	}

	.lgm-index-4 {
		width: 100%;
		height: 100%;
		background-image: url('../assets/images/product_7.png');
		background-size: cover;
		background-position: center;

		.lgm-index-4-child {
			width: 1280px;
			margin: 0 auto;

			.title {
				padding-top: 327px;
				font-size: 40px;
				margin-bottom: 40px;
			}

			.text {
				width: 620px;
				line-height: 28px;
				margin-bottom: 60px;
			}

			.btn {
				width: 192px;
				height: 48px;
				background-image: linear-gradient(to right, #1664FF, rgb(22 100 255 / 50%)) !important;
				text-align: center;
				line-height: 48px;
				border-radius: 100px;
				color: #fff;
				cursor: pointer;
			}
		}
	}

	.lgm-index-6 {
		width: 100%;
		height: 932px;
		background-color: rgb(22 100 255 / 8%);

		.lgm-index-6-child {
			width: 1280px;
			margin: 0 auto;

			.title {
				font-size: 40px;
				text-align: center;
				padding-top: 187px;
			}

			.title2 {
				text-align: center;
				margin-top: 24px;
				color: #ccc;
			}

			.hzhb {
				margin-top: 100px;
				display: flex;
				flex-wrap: wrap;

				.item {
					width: 290px;
					height: 174px;
					background-color: #fff;
					margin-right: 40px;
					margin-bottom: 40px;

					&:nth-child(4n) {
						margin-right: 0;
					}
				}

				.more {
					display: flex;
					justify-content: center;
					align-items: center;

					.more-item {
						width: 10px;
						height: 10px;
						background-color: #ccc;
						border-radius: 50%;
						margin: 0 10px;
					}
				}
			}
		}
	}


	.text3 {
		position: absolute;
		top: 100px;
		left: 41.6%;
		font-size: 40px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #020814;
		line-height: 36px;
	}

	.app_list {
		position: absolute;
		top: 100px;
		left: 1052px;
	}

	.text4 {
		width: 318px;
		height: 40px;
		font-size: 40px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #020814;
		margin-bottom: 70px;
		line-height: 40px
	}

	.app_text {
		width: 550px;
		height: 96px;
		font-size: 16px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #020814;
		line-height: 32px;
	}

	.text5 {
		position: absolute;
		top: 200px;
		right: 552px;
		width: 318px;
		height: 40px;
		font-size: 40px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #020814;
		line-height: 40px
	}


	.picture {
		position: absolute;
		top: 256px;
		left: 320px;
	}

	.support {
		position: absolute;
		top: 327px;
		left: 320px;
		// left: 320px;
	}

	.support-tit {
		font-size: 40px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #020814;
		line-height: 40px;
		margin-bottom: 40px;
	}

	.support-btn {
		width: 192px;
		height: 48px;
		background: linear-gradient(270deg, rgba(22, 100, 255, 0.5) 0%, #1664FF 100%);
		border-radius: 500px 500px 500px 500px;
		opacity: 1;
		font-size: 16px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 48px;
		text-align: center;
	}

	.support-text {
		width: 620px;
		height: 96px;
		font-size: 16px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #020814;
		line-height: 32px;
		margin-bottom: 60px;
	}

	.picture_img {
		width: 144px;
		height: 144px;
		margin-bottom: 60px;
	}

	.picture_tit {
		font-size: 32px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: bold;
		color: #020814;
		line-height: 32px;
		margin-bottom: 40px;
	}

	.picture_text {
		width: 550px;
		height: 192px;
		font-size: 16px;
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: 400;
		color: #020814;
		line-height: 32px;
	}

	#carousel {
		width: 100%;
		height: calc(100vh - 80px);
		position: relative;
	}

	.tagList {
		position: fixed;
		top: 160px;
		right: 70px;
		z-index: 333;
	}

	.tagItem {
		width: 20px;
		height: 20px;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
		border-radius: 10px 10px 10px 10px;
		background: #020814;
		opacity: 0.2;
		margin-bottom: 20px;
		cursor: pointer;
	}

	.tagItem_actve {
		background: #1664FF;
		opacity: 1;
	}


	.swiper-container {
		width: 100%;
		height: calc(100vh - 100px);
	}

	.swiper-slide {
		background: red;
	}



	/deep/ .el-carousel__item--card {
		width: 40%;

	}
</style>
