import { render, staticRenderFns } from "./aboutUs.vue?vue&type=template&id=1a6f8b7a&scoped=true"
import script from "./aboutUs.vue?vue&type=script&lang=js"
export * from "./aboutUs.vue?vue&type=script&lang=js"
import style0 from "./aboutUs.vue?vue&type=style&index=0&id=1a6f8b7a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a6f8b7a",
  null
  
)

export default component.exports