<template>
  <div class="container">

    <div class="main">
      <div class="child">
        <div class="title">蕙漾馨福智慧健康酒店</div>
        <div class="text">蕙漾馨福智慧健康酒店是一家集健康养老、休闲娱乐、旅游度假、商务入驻、商业接待、专业直播、结婚礼堂等功能内容为一体的综合型连锁自主式服务会员酒店，是蕙漾馨福投资集团健康、婚恋、养老、旅游业务板块的重要承接平台之一。</div>
      </div>
    </div>

    <div class="content">
      <div class="title">
        功能介绍
      </div>
      <div class="info">
        <div class="item" v-for="(item, index) of list" :key="index">
          <div class="title2">
            {{ item.title }}
          </div>
          <div class="text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
  </div>
</template>

<script>
  import HyxfFooter from '@/components/footer'

  export default {
    name: 'productPlatform',
    data() {
      return {
        list: [
          {
            title: '贴心关怀，健康相伴',
            text: '蕙漾馨福智慧健康酒店注重人文关怀理念，将“健康”作为酒店服务管理的一大核心要求，围绕这一要点，为客户提供绿色、健康、营养、舒适的使用体验。',
          },
          {
            title: '科技AI，智能高效',
            text: '酒店拥有一套完善的智能化体系，搭载智能门禁系统、智能取电开关、交互视频系统、电脑网络系统、信息展示系统、智能互动系统、信息查看系统等多维系统，通过数字化和网络化 ，实现酒店管理和服务的信息化。',
          },
          {
            title: '功能齐全，适配场景',
            text: '酒店开设有健康疗养中心、休闲度假中心，提供客房、餐厅、温泉、高尔夫球场、马场、养生道馆、结婚礼堂、直播套房、高端会议室、户外运动场、真人cs练场等功能区域，适配不同客户的不同使用场景。',
          },
          {
            title: '高端硬件，优质服务',
            text: '酒店配置高端硬件，包括电子门锁、智能马桶、智能淋浴系统、智能窗帘、智能床垫、智能语音、智能灯光、智能空气净化器、智能垃圾桶、智能吸尘器、智能电视、智能投影仪等，为客户提供便捷高效的优质服务。',
          }
        ]
      }
    },
    components: {
      HyxfFooter
    },
    methods: {
      changeMenu(index) {
        this.$emit('childChangeMenu', index)
      },
    }


  };
</script>




<style lang="less" scoped>
  .container {
    width: 100%;

    .main {
      width: 100%;
      height: 500px;
      background-image: url('@/assets/images/product_17.png');
      background-size: cover;
      background-position: center;
      .child {
        width: 1280px;
        margin: 0 auto;
        .title {
          font-size: 60px;
          font-weight: 600;
          margin-bottom: 40px;
          padding-top: 140px;
        }

        .text {
          line-height: 28px;
          width: 620px;
          opacity: 0.8;
        }
      }
    }

    .content {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      .title {
        text-align: center;
        padding: 100px 0 80px;
        font-size: 24px;
      }
      .info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 100px;
        .item {
          width: 550px;
          height: 276px;
          border-radius: 8px;
          padding: 0 35px;
          border: 1px solid #eee;
          cursor: pointer;
          margin-bottom: 40px;
          .title2 {
            margin-top: 78px;
            margin-bottom: 40px;
            font-size: 32px;
          }
          .text {
            opacity: 0.6;
            line-height: 28px;
          }
          &:hover {
            background: linear-gradient(to bottom, rgb(22 100 255 / 0%), rgb(22 100 255 / 8%));
          }
        }
      }
    }


  }
</style>
