<template>
	<div class="container">

		<div class="main">
			<div class="title">联系我们</div>
			<div class="title2">细心沟通，高效对接，品质承诺</div>
		</div>

		<div class="content">
			<div class="title title-copy">
				联系方式
			</div>
			<div class="info">
				<div class="item" v-for="(item, index) of list" :key="index">
					<div class="left">
						<img :src="item.url" alt="" />
					</div>
					<div class="right">
						<div class="text">{{ item.text }}</div>
						<span class="text2">{{ item.text2 }}</span>
						<div class="text3">{{ item.text3 }}</div>
					</div>
				</div>
			</div>
			<div class="title">
				关注我们
			</div>
			<div class="us">
				<div class="left">
					<img src="@/assets/images/wx_gzh.png" alt="" />
					<p class="text">蕙漾馨福婚恋</p>
					<p class="text2">官方微信公众号</p>
				</div>
				<div class="left right">
					<img src="@/assets/images/gfwb.png" alt="" />
					<p class="text">蕙漾馨福官方号</p>
					<p class="text2">官方微博</p>
				</div>
			</div>
		</div>
		<hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
	</div>
</template>

<script>
	import HyxfFooter from '@/components/footer'

	export default {
		name: 'JoinUs',
		data() {
			return {
				list: [{
						url: require("../assets/images/contactus_2.png"),
						text: '公司地址',
						text2: '深圳市南山区招商前海经贸中心一期B座906'
					},
					{
						url: require("../assets/images/contactus_3.png"),
						text: '座机号码',
						text2: '周一至周日 9:00-22:00',
						text3: '0755-33308356'
					},
					{
						url: require("../assets/images/contactus_4.png"),
						text: '客服电话',
						text2: '周一至周日 9:00-22:00',
						text3: '400-152-5188'
					},
					{
						url: require("../assets/images/contactus_5.png"),
						text: '商务合作&代理',
						text2: 'HYXF008@aliyun.com'
					},
					{
						url: require("../assets/images/contactus_6.png"),
						text: '市场推广&品牌合作',
						text2: 'HYXF008@aliyun.com',
					},
					{
						url: require("../assets/images/contactus_7.png"),
						text: '公关对接&专访接待',
						text2: 'HYXF008@aliyun.com',
					},
				]
			}
		},
		components: {
			HyxfFooter
		},
		methods: {
			changeMenu(index) {
				this.$emit('childChangeMenu', index)
			},
		}


	};
</script>




<style lang="less" scoped>
	.container {
		width: 100%;

		.main {
			height: 100%;
			height: 500px;
			background-image: url('@/assets/images/contactus_1.png');
			background-size: cover;
			background-position: center;
			text-align: center;

			.title {
				padding-top: 174px;
				font-size: 60px;
				font-weight: bold;
				margin-bottom: 40px;
			}

			.title2 {
				font-size: 32px;
				opacity: 0.8;
			}
		}

		.content {
			width: 1280px;
			height: 100%;
			margin: 0 auto;

			.title-copy {
				margin-bottom: 40px !important;
			}

			.title {
				text-align: center;
				font-size: 40px;
				margin-top: 118px;
				margin-bottom: 80px;
			}

			.us {
				width: 100%;
				height: 500px;
				background-image: url('@/assets/images/contactus_8.png');
				background-size: cover;
				background-position: center;
				display: flex;
				justify-content: center;

				.right {
					margin-right: 0px !important;
				}

				.left {
					margin-right: 200px;
					text-align: center;

					img {
						width: 300px;
						height: 300px;
					}

					.text {
						font-size: 24px;
						margin: 30px 0 24px;

						.text2 {
							opacity: 0.6;
						}
					}
				}
			}

			.info {
				display: flex;
				flex-wrap: wrap;

				.item {
					width: 400px;
					height: 186px;
					padding: 32px 20px;
					background: #FFFFFF;
					border-radius: 8px;
					border: 1px solid #F5F5F5;
					margin-right: 24px;
					margin-top: 40px;
					display: flex;
					justify-content: space-between;
					box-sizing: border-box;

					.left {
						width: 120px;
						height: 120px;
						margin-right: 24px;

						img {
							width: 100%;
						}
					}

					.right {
						flex: 1;
						display: flex;
						flex-direction: column;
						height: 120px;
						justify-content: space-around;

						.text {
							font-size: 24px;
							margin-bottom: 20px;
						}

						.text2 {
							opacity: 0.6;
						}

						.text3 {
							margin-top: 16px;
							font-size: 24px;
						}
					}
				}
			}
		}


	}
</style>