<template>
	<div class="header-wrap">
		<div class="header">
			<div class="logo"></div>

			<div class="menu">
				<div class="item" :class="{'active': menuIndex === '1'}" @click="jumpPage('1')">网站首页</div>
				<div class="item" :class="{'active': menuIndex === '2'}" @click="jumpPage('2')">产品介绍</div>
				<div class="item" :class="{'active': menuIndex === '3'}" @click="jumpPage('3')">服务支持</div>
				<div class="item" :class="{'active': menuIndex === '4'}" @click="jumpPage('4')" @mouseenter="menuEnter"
					@mouseleave="menuLeave">
					关于我们
					<el-collapse-transition>
						<div class="hide-menu" v-show="hideMenu">
							<div class="menu-child-item" :class="{'child-active': hideMenuIndex === '5'}"
								@click.stop="jumpPage('5')">
								公司简介</div>
							<div class="menu-child-item" :class="{'child-active': hideMenuIndex === '6'}"
								@click.stop="jumpPage('6')">
								企业文化</div>
							<!-- <div class="menu-child-item" :class="{'child-active': hideMenuIndex === '7'}" @click.stop="jumpPage('7')">
                新闻动态</div> -->
							<div class="menu-child-item" :class="{'child-active': hideMenuIndex === '8'}"
								@click.stop="jumpPage('8')">
								联系我们</div>
						</div>
					</el-collapse-transition>
				</div>
				<div class="item" :class="{'active': menuIndex === '9'}" @click="jumpPage('9')">加入我们</div>
			</div>



			<div class="phone">
				<img src="@/assets/images/index/tel.png">
				<p>400-152-5188</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HyxfHeader',
		data() {
			return {
				menuIndex: '1',
				hideMenuIndex: '999',
				mineActive: true,
				productActive: false,
				serviceActive: false,
				aboutUsActive: false,
				joinUsActive: false,
				hideMenu: false,
				storageMenu: ''
			}
		},
		props: ['index'],
		watch: {
			$route(to, from) {
				console.log(to.query, this.menuIndex)
				if (to.query && to.query.active) {
					this.menuIndex = to.query.active
				}
				
				window.scrollTo(0, 0)
				
			},
			index(val) {
				if (val) {
					this.menuIndex = val
					if (this.menuIndex === '4') {
						this.hideMenuIndex === '1'
					}
				} else {
					this.menuIndex = '1'
				}
			},
			storageMenu: {
				handler(val) {
					this.menuIndex = val
					console.log('监听', this.menuIndex)
				},
				deep: true
			}
		},
		created() {
			this.jumpPage('1')
		},
		methods: {
			menuEnter() {
				this.hideMenu = true
			},
			menuLeave() {
				this.hideMenu = false
			},
			jumpPage(index) {
				this.menuIndex = index

				sessionStorage.removeItem('menuIndex')

				if (this.menuIndex === '4') {
					this.hideMenuIndex = '5'
				} else {
					this.hideMenuIndex = index
				}

				if (this.hideMenuIndex === '5' || this.hideMenuIndex === '6' || this.hideMenuIndex === '7' || this
					.hideMenuIndex === '8') {
					this.menuIndex = '4'
				}


				switch (index) {
					case '1':
						this.$router.push({
							name: 'home'
						}, () => {
							console.log('')
						})
						break
					case '2':
						this.$router.push({
							name: 'product'
						}, () => {
							console.log('')
						})
						break
					case '3':
						this.$router.push({
							name: 'service'
						}, () => {
							console.log('')
						})
						break
					case '4':
						this.$router.push({
							name: 'aboutUs'
						}, () => {
							console.log('')
						})
						break
					case '5':
						this.$router.push({
							name: 'aboutUs'
						}, () => {
							console.log('')
						})
						break
					case '6':
						this.$router.push({
							name: 'busCulture'
						}, () => {
							console.log('')
						})
						break
					case '7':
						this.$router.push({
							name: 'news'
						}, () => {
							console.log('')
						})
						break
					case '8':
						this.$router.push({
							name: 'contactUs'
						}, () => {
							console.log('')
						})
						break
					case '9':
						this.$router.push({
							name: 'joinUs'
						}, () => {
							console.log('')
						})
						break
				}
			},

			jumpChildPage(index) {
				this.hideMenuIndex = index
				this.$emit('changeMenu', this.hideMenuIndex)
				if (this.hideMenuIndex === '5') {
					this.menuIndex = '4'
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.header-wrap {
		width: 100%;
		height: 80px;
		background-color: #1B2B4F;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999999;

		.header {
			width: 1280px;
			height: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.logo {
				width: 192px;
				height: 56px;
				background-image: url('@/assets/images/index/img1.png');
				background-size: 100%;
				margin-right: 50px;
				background-repeat: no-repeat;
				background-position: center;
			}

			.menu {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #fff;
				font-size: 18px;
				flex: 1;

				.item {
					width: 144px;
					cursor: pointer;
					text-align: center;
					position: relative;
					padding: 28px 0;

					&:nth-of-type(4) {}

					.hide-menu {
						position: absolute;
						top: 80px;
						z-index: 100;

						.menu-child-item {
							width: 144px;
							height: 72px;
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: rgb(27 43 79 / 80%);
							font-size: 18px;
							color: #fff;
						}
					}
				}

				.active {
					color: #1664FF;
				}

				.child-active {
					background-color: rgb(22 100 255 / 80%) !important;
				}
			}

			.phone {
				display: flex;
				align-items: center;
				margin-left: 62px;

				img {
					width: 32px;
					height: 32px;
				}

				p {
					font-size: 28px;
					color: #fff;
					margin-left: 8px;
				}
			}
		}
	}
</style>