<template>
  <div class="container">

    <div class="main">
      <div class="child">
        <div class="title">蕙漾馨福智慧未来餐厅</div>
        <div class="text">
          蕙漾馨福智慧未来餐厅是蕙漾馨福网络婚恋平台的线下承载实体。餐厅以“缘份”的人文思想为出处，以美食为载体，以现代科技为纽带，营造充满爱情、亲情、友情的心灵港湾。
        </div>
      </div>
    </div>

    <div class="content">
      <div class="title">
        功能介绍
      </div>
      <div class="info">
        <div class="item" v-for="(item, index) of list" :key="index">
          <div class="title2">
            {{ item.title }}
          </div>
          <div class="text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
  </div>
</template>

<script>
  import HyxfFooter from '@/components/footer'

  export default {
    name: 'productPlatform',
    data() {
      return {
        list: [
          {
            title: '优质食材，蕙漾严选',
            text: '蕙漾馨福智慧未来餐厅以人文服务为第一要旨，面向客户提供菜品，原材料均为蕙漾馨福采购团队品质严选，只为客户提供健康、新鲜、优质的食材。',
          },
          {
            title: '特级厨师，美味保证',
            text: '餐厅厨师团队来自五湖四海不同地域，特级职称，厨艺精良，精通不同地方菜的处理加工，工艺精细、品类繁多、中西结合、美味鲜香。',
          },
          {
            title: '高端器材，品质要求',
            text: '餐厅配置各类高端器材，涵盖种类包括高端烹饪设备、食材加工设备、烘焙设备、餐具酒具、洗涤设备及其他配置用品。提供烹、煮、煎、炸、炖、炒、烧、烤、熬等多种食材处理途径。',
          },
          {
            title: '智慧搭载，高效便捷',
            text: '餐厅搭载智慧管理体系：为客户提供自主点餐系统、服务呼叫系统、预定牌号系统等，帮助客户自主高效点餐；为餐厅管理人员提供后厨互动系统、前台收银系统、信息管理系统等，帮助餐厅精准高效管理。',
          },
          {
            title: '贴近自然，放松氛围',
            text: '餐厅主打“健康”、“自然”的品牌元素，提倡天人合一的思想理念，餐厅布局与设计上多用绿植景观陈设，营造融景自然的和谐环境。',
          },
          {
            title: '专业团队，经验服务',
            text: '蕙漾馨福智慧未来餐厅拥有专业的经营管理团队，经验丰富，熟悉业务管理全流程服务，从前台到后厨，从预定到售后，给予客户最人性化的良好体验。',
          }
        ]
      }
    },
    components: {
      HyxfFooter
    },
    methods: {
      changeMenu(index) {
        this.$emit('childChangeMenu', index)
      },
    }


  };
</script>




<style lang="less" scoped>
  .container {
    width: 100%;

    .main {
      width: 100%;
      height: 500px;
      background-image: url('@/assets/images/product_19.png');
      background-size: cover;
      background-position: center;
      .child {
        width: 1280px;
        margin: 0 auto;
        .title {
          font-size: 60px;
          font-weight: 600;
          margin-bottom: 40px;
          padding-top: 140px;
        }

        .text {
          line-height: 28px;
          width: 620px;
          opacity: 0.8;
        }
      }
    }

    .content {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      .title {
        text-align: center;
        padding: 100px 0 80px;
        font-size: 24px;
      }
      .info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 100px;
        .item {
          width: 550px;
          height: 276px;
          border-radius: 8px;
          padding: 0 35px;
          border: 1px solid #eee;
          cursor: pointer;
          margin-bottom: 40px;
          .title2 {
            margin-top: 78px;
            margin-bottom: 40px;
            font-size: 32px;
          }
          .text {
            opacity: 0.6;
            line-height: 28px;
          }
          &:hover {
            background: linear-gradient(to bottom, rgb(22 100 255 / 0%), rgb(22 100 255 / 8%));
          }
        }
      }
    }


  }
</style>
