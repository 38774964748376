<template>
  <div class="container">

    <div class="main">
      <div class="title">加入我们</div>
      <div class="title2">在这里可以和最优秀的人一起工作</div>
    </div>

    <div class="content">
      <div class="info">
        <div class="item" v-for="(item, index) of list" :key="index">
          <div class="left">
            <h3 class="row">{{ item.name }}</h3>
            <div class="row2">
              <span>{{ item.time }}</span>
              <span>{{ item.xl }}</span>
            </div>
            <div class="row3">
              工作地点：{{ item.address }}
            </div>
          </div>

          <div class="right">
            <p>招聘人数：<span>{{ item.num }}人</span></p>
          </div>
          <div class="icon"></div>
        </div>
      </div>
    </div>
    <hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
  </div>
</template>

<script>
  import HyxfFooter from '@/components/footer'

  export default {
    name: 'JoinUs',
    data() {
      return {
        list: [
          {
            name: '资深系统安全攻防专家',
            num: 1,
            address: '深圳市/南山区',
            xl: '本科',
            time: '5-10年'
          },
          {
            name: '资深DBA数据库架构师',
            num: 2,
            address: '深圳市/南山区',
            xl: '本科',
            time: '5-10年'
          },
          {
            name: '资深播放器SDK工程师',
            num: 2,
            address: '深圳市/南山区',
            xl: '本科',
            time: '5-10年'
          },
          {
            name: '资深匹配推荐算法工程师',
            num: 1,
            address: '深圳市/南山区',
            xl: '本科',
            time: '5-10年'
          },
          {
            name: '资深API平台研发架构师',
            num: 1,
            address: '深圳市/南山区',
            xl: '本科',
            time: '5-10年'
          },
          {
            name: '资深提现风控算法工程师',
            num: 2,
            address: '深圳市/南山区',
            xl: '本科',
            time: '5-10年'
          },
          {
            name: '资深内容理解算法工程师',
            num: 1,
            address: '深圳市/南山区',
            xl: '本科',
            time: '5-10年'
          },
          {
            name: '资深UE4高级礼物特效师',
            num: 3,
            address: '深圳市/南山区',
            xl: '本科',
            time: '5-10年'
          }
        ]
      }
    },
    components: {
      HyxfFooter
    },
    methods: {
      changeMenu(index) {
        this.$emit('childChangeMenu', index)
      },
    }


  };
</script>




<style lang="less" scoped>
  .container {
    width: 100%;

    .main {
      height: 100%;
      height: 500px;
      background-image: url('@/assets/images/product_13.png');
      background-size: cover;
      background-position: center;
      text-align: center;
      .title {
        padding-top: 174px;
        font-size: 60px;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .title2 {
        font-size: 32px;
        opacity: 0.8;
      }
    }

    .content {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
    }

    .info {
      margin-top: 100px;
      margin-bottom: 100px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 535px;
        height: 164px;
        padding: 40px;
        border: 1px solid #f5f5f5;
        border-radius: 8px;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 40px;
        &:nth-child(2n) {
          margin-right: 0px;
        }
        &:hover {
          background-image: linear-gradient(to bottom, rgb(22 100 255 / 0%), rgb(22 100 255 / 8%));
        }
        .left {
          .row {
            color: #000;
            font-size: 24px;
            margin-bottom: 24px;
          }
          .row2 {
            margin-bottom: 38px;
            span {
              display: inline-block;
              width: 73px;
              height: 28px;
              text-align: center;
              line-height: 28px;
              font-size: 14px;
              margin-right: 8px;
              background-color: #F5F5F5;
              border-radius: 4px;
            }
          }

          .row3 {
            opacity: 0.6;
          }
        }
        .right {
          margin-top: 16px;
          p {
            opacity: 0.6;
            span {
              color: #1664FF;
            }
          }
        }
        .icon {
          width: 84px;
          height: 100px;
          position: absolute;
          background-image: url('@/assets/images/joinus_1.png');
          background-size: cover;
          background-position: center;
          left: -5px;
          top: 52px;
        }
      }
    }
  }
</style>
