<template>
	<div class="container">

		<div class="main">
			<div class="child">
				<div class="title">蕙漾馨福婚恋平台</div>
				<div class="text">一个全新的短视频婚恋社区综合型应用平台，秉承着“铸造天下馨福婚姻”的理念，致力于帮助单身男女寻找合适的伴侣，成就美满的婚姻。</div>
			</div>
		</div>

		<div class="content">
			<div class="title">
				功能介绍
			</div>
			<div class="info">
				<div class="item" v-for="(item, index) of list" :key="index">
					<div class="title2">
						{{ item.title }}
					</div>
					<div class="text">
						{{ item.text }}
					</div>
				</div>
			</div>
		</div>
		<hyxf-footer @changeMenu="changeMenu"></hyxf-footer>
	</div>
</template>

<script>
	import HyxfFooter from '@/components/footer'

	export default {
		name: 'productPlatform',
		data() {
			return {
				list: [{
						title: '聊天找对象',
						text: '我们提供简约便捷的平台找对象功能，用户可与其他平台用户展开一对一私聊，允许发送文字、语音、照片、视频等内容，配置功能齐全的私聊空间。',
					},
					{
						title: '用户真实信息展示',
						text: '我们提供内容丰富的话题广场展示功能，平台每一位用户均可成为优质内容的输出者，图文形式分享美好生活，用户可发布、参与话题内容建设输出，找到志同道合的人群。',
					},
					{
						title: '精准用户推荐',
						text: '我们提供精准的用户匹配推荐功能，根据用户预先设置好的基本信息与交友条件，对用户进行高匹配度的用户推荐，提高用户交友效率。同时用户也可根据自身需要主动进行条件筛选，更加精准地找到符合自身期望的用户对象。',
					},
					{
						title: '话题广场',
						text: '我们提供内容丰富的话题广场展示功能，平台每一位用户均可成为优质内容的输出者，图文形式分享美好生活，用户可发布、参与话题内容建设输出，找到志同道合的人群。',
					},
					{
						title: '短视频社区',
						text: '我们提供短视频展示与发布功能，用户可浏览短视频内容，点赞评论分享等，亦可自己拍摄短视频内容，分享生活点滴。',
					},
					{
						title: '馨福币体系',
						text: '用户可以充值馨福币购买线上礼物，赠送给短视频作品或用户个人。线上礼品可兑换成馨福币，馨福币可提现至银行卡。',
					}
				]
			}
		},
		components: {
			HyxfFooter
		},
		methods: {
			changeMenu(index) {
				this.$emit('childChangeMenu', index)
			},
		}


	};
</script>




<style lang="less" scoped>
	.container {
		width: 100%;

		.main {
			width: 100%;
			height: 500px;
			background-image: url('@/assets/images/product_18.png');
			background-size: cover;
			background-position: center;

			.child {
				width: 1280px;
				margin: 0 auto;

				.title {
					font-size: 60px;
					font-weight: 600;
					margin-bottom: 40px;
					padding-top: 140px;
				}

				.text {
					line-height: 28px;
					width: 620px;
					opacity: 0.8;
				}
			}
		}

		.content {
			width: 1280px;
			height: 100%;
			margin: 0 auto;

			.title {
				text-align: center;
				padding: 100px 0 80px;
				font-size: 24px;
			}

			.info {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				padding-bottom: 100px;

				.item {
					width: 550px;
					height: 276px;
					border-radius: 8px;
					padding: 0 35px;
					border: 1px solid #eee;
					cursor: pointer;
					margin-bottom: 40px;

					.title2 {
						margin-top: 78px;
						margin-bottom: 40px;
						font-size: 32px;
					}

					.text {
						opacity: 0.6;
						line-height: 28px;
					}

					&:hover {
						background: linear-gradient(to bottom, rgb(22 100 255 / 0%), rgb(22 100 255 / 8%));
					}
				}
			}
		}


	}
</style>