<template>
	<div class="footer-wrap">
		<div class="footer">

			<div class="top">
				<div class="left">
					<div class="img"></div>
				</div>
				<div class="right">
					<p>网站导航</p>
					<p>行业资讯</p>
					<p>关注我们</p>
				</div>
			</div>

			<div class="bottom">
				<div class="left">
					<div class="item">
						<img src="../assets/images/product_9.png" alt="">
						<div class="text">400咨询</div>
						<div class="info">400-152-5188</div>
						<div class="text">座机号码</div>
						<div class="info">0755-33308356</div>
					</div>

					<div class="item">
						<img src="../assets/images/product_10.png" alt="">
						<div class="text">技术支持QQ</div>
						<div class="info">3679904950</div>
						<div class="text">
							(工作日10:00-19:00)
						</div>
					</div>

					<div class="item">
						<img src="../assets/images/product_11.png" alt="">
						<div class="text">产品建议邮箱</div>
						<div class="info">HYXF008@aliyun.com</div>
					</div>
				</div>

				<div class="right">
					<div class="item-wrap">
						<p @click="jumpPage('1')">网站首页</p>
						<p @click="jumpPage('2')">产品介绍</p>
						<p @click="jumpPage('3')">服务支持</p>
						<p @click="jumpPage('4')">关于我们</p>
						<p @click="jumpPage('5')">加入我们</p>
					</div>
					<div class="item-wrap">
						<p @click="jumpPage('6')">智慧酒店</p>
						<p>数据分析</p>
						<p>婚恋市场</p>
						<p>数据治理</p>
						<p @click="jumpPage('7')">智慧餐饮</p>
					</div>
					<div class="code">
						<img src="../assets/images/product_12.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<div class="icp">
			Copyright © www.hyxf5188.com, All Rights Reserved. <a style="text-decoration: none;color: #fff;" href="https://beian.miit.gov.cn/#/Integrated/index"> 粤ICP备2023130472号</a> 蕙漾馨福——铸造天下馨福婚姻
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HyxfFooter',
		data() {
			return {

			}
		},
		methods: {
			jumpPage(index) {
				switch (index) {
					case '1':
						this.$router.push({
							path: '/?active=1'
						})
						break
					case '2':
						this.$router.push({
							path: '/product?active=2'
						})
						break
					case '3':
						this.$router.push({
							path: '/service?product=3'
						})
						break
					case '4':
						this.$router.push({
							path: '/aboutUs?active=4'
						})
						break
					case '5':
						this.$router.push({
							path: '/joinUs?active=9'
						})
						break
					case '6':
						this.$router.push({
							path: '/productHotel?active=2'
						})
						break
					case '7':
						this.$router.push({
							path: '/productRoom?active=2'
						})
						break

				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.footer-wrap {
		width: 100%;
		height: 600px;
		background-color: #1B2B4F;
		position: relative;
		.icp {
			width: 100%;
			height: 76px;
			line-height: 76px;
			text-align: center;
			color: #fff;
			font-size: 14px;
			opacity: 0.5;
			position: absolute;
			left: 0;
			bottom: 0;
			border-top: 1px solid rgb(255 255 255 / 8%);
		}
		.footer {
			width: 1280px;
			height: 100%;
			margin: 0 auto;

			.top {
				padding-top: 100px;
				border-bottom: 1px solid rgba(255, 255, 255, 0.08);
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.left {
					.img {
						width: 164px;
						height: 48px;
						background-image: url('../assets/images/product_8.png');
						background-size: 100%;

					}
				}

				.right {
					display: flex;
					justify-content: space-between;

					p {
						margin: 0 40px;
						font-size: 18px;
						color: #fff;

						&:last-child {
							margin-right: 48px;
						}
					}
				}
			}

			.bottom {
				margin-top: 33px;
				display: flex;

				.left {
					display: flex;
					width: 856px;
					justify-content: space-between;

					.item {
						&:last-child {
							margin-right: 118px;
						}

						img {
							width: 44px;
							height: 44px;
							margin-bottom: 20px;
						}

						.text {
							font-size: 14px;
							color: #fff;
							opacity: 0.5;
						}

						.info {
							margin-top: 5px;
							font-size: 24px;
							color: #fff;
							margin-bottom: 30px;
							opacity: 0.5;
						}
					}
				}

				.right {
					flex: 1;
					display: flex;
					justify-content: space-between;

					.item-wrap {
						// margin-right: 50px;
						opacity: 0.5;

						p {
							font-size: 14px;
							color: #fff;
							margin-bottom: 30px;
							margin-top: 0;
							cursor: pointer;
						}
					}

					.code {
						width: 120px;
						height: 150px;

						img {
							width: 100%;
						}
					}
				}
			}

		}
	}
</style>
