import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import newsDetail from '../views/newsDetail.vue'
import service from '../views/service.vue'
import product from '../views/product.vue'
import aboutUs from '../views/aboutUs.vue'
import busCulture from '../views/busCulture.vue'
import joinUs from '../views/joinUs.vue'
import news from '../views/news.vue'
import contactUs from '../views/contactUs.vue'
import productPlatform from '../views/productPlatform.vue'
import productRoom from '../views/productRoom.vue'
import productHotel from '../views/productHotel.vue'



Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // routes
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/newsDetail',
      name: 'newsDetail',
      component: newsDetail
    },
    {
      path: '/service',
      name: 'service',
      component: service
    },
    {
      path: '/product',
      name: 'product',
      component: product
    },
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: aboutUs
    },
    {
      path: '/busCulture',
      name: 'busCulture',
      component: busCulture
    },
    {
      path: '/joinUs',
      name: 'joinUs',
      component: joinUs
    },
    {
      path: '/contactUs',
      name: 'contactUs',
      component: contactUs
    },
    {
      path: '/news',
      name: 'news',
      component: news
    },
    {
      path: '/productPlatform',
      name: 'productPlatform',
      component: productPlatform
    },
    {
      path: '/productRoom',
      name: 'productRoom',
      component: productRoom
    },
    {
      path: '/productHotel',
      name: 'productHotel',
      component: productHotel
    },
  ]
})

export default router
